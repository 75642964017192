var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"table-bordered table-hover",attrs:{"items":_vm.mainList.dataset,"fields":_vm.tableFields,"busy":_vm.onLoadingList},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('vcl-table',{attrs:{"speed":5,"animate":true,"columns":10}})]},proxy:true},{key:"cell(index)",fn:function(row){return [_vm._v(" "+_vm._s(row.index + 1)+" ")]}},{key:"cell(id)",fn:function(row){return [(
        [
          _vm.TRANSACTION_TYPE.CashIn,
          _vm.TRANSACTION_TYPE.CashOut,
          _vm.TRANSACTION_TYPE.BankIn,
          _vm.TRANSACTION_TYPE.BankOut ].includes(row.item.type)
      )?_c('span',[_c('router-link',{attrs:{"to":{
          path: '/accounting/transactions/cashbook',
          query: { id: row.item.id },
        },"target":"_blank"}},[_vm._v(_vm._s(row.item.id)+" ")])],1):_c('span',[_vm._v(" "+_vm._s(row.item.id)+" ")])]}},{key:"cell(createdAt)",fn:function(row){return [_c('span',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.formatDate(row.item.createdAt)))])]}},{key:"cell(amount)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.convertPrice(row.item.amount))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }